<template>
    <section>
        <div>
            <div class="float">
                <transition name="widget">
                    <div class="card mx-2" v-show="details">
                        <div class="card-header d-flex justify-content-between">
                            <span>
                                Mes réservations
                            </span>
                            <span class="pointer" @click="details = false">x</span>
                        </div>
                        <div class="card-body p-2">
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Nom</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody v-if="services.length>0">
                                    <tr v-for="(reservation, reservationid) in services" :key="reservationid">
                                        <td class="name">{{reservation.name}}</td>
                                        <td class="text-center"><i class="fa fa-trash pointer" @click="removeServiceToCart(reservationid)"></i></td>
                                    </tr>
                                </tbody>
                                <tbody v-else class=" text-muted">
                                    Vous n'avez pas de réservation
                                </tbody>
                            </table>
                        </div>
                        <div class="card-footer">
                            <button class="btn btn-block btn-sm btn-primary" @click="reservation()">Valider mes rendez-vous</button>
                        </div>
                    </div>
                </transition>
            </div>
            <button type="button" class="btn btn- btn-social-icon-text btn-dribbble btn-float" @click="details =! details"><i class="mdi mdi-calendar"></i>VOIR LES RESERVATIONS</button>
        </div>
    </section>
</template>

<script>
import {mapState} from 'vuex'
export default {
    name: "float-reservation",
    data () {
        return {
            details: false
        }
    },
    computed: {
        ...mapState({
            services: state=> state.cart.services
        }),
    },
    methods:{
        removeServiceToCart(index){
            //remove service in vuex
            this.$store.dispatch('removeServiceToCart',index)
        },
        reservation () {
            this.$router.push({
                name:"reservation"
            })
        }
    },
    mounted(){
    }
}
</script>
<style scoped>
.float {
    position: fixed;
    width: 100%;
    max-width: 20rem;
    height: auto;
    bottom: 0vh;
    right: 0vh;
    z-index: 3001;
}
.card{
    background: rgb(255, 255, 255) !important;
    height: 70vh;
    max-height: 70vh
}
.card-header {
    background:#c49f2e;
    color: #fff;
}
.btn-float {
    background: #d4b44e;
    position: fixed;
    bottom: 1vh;
    right:1vh;
    z-index: 3000;
    min-height: 3.5rem;
}
.btn-dribbble.btn-social-icon-text i {
    /* background: #c49f2e !important; */
    background: none !important;
}
/*tableau*/
.name {
    max-width: 13rem;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}
/* animation */
.widget-enter-active, .widget-leave-active {
  transition: opacity .5s;
}
.widget-enter, .widget-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>